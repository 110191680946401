// src/components/About.js
import React, { useState } from "react";
import "./../styles/styles.css";

import { addDoc, collection } from "firebase/firestore";
import { db } from "./../../../firebase";
import SidNav from "./sidenav";

const { v4: uuidv4 } = require("uuid");

function View() {
  const [projectName, setprojectName] = useState("");
  const [projectDesc, setprojectDesc] = useState("");
  const [projectPath, setprojectPath] = useState("");
  const [projectRoutes, setprojectRoutes] = useState("");
  const [loading, setloading] = useState(false);

  const dbRef = collection(db, "Projects");

  function stringToList(inputString) {
    // Remove square brackets and split the string by commas
    const trimmedArray = inputString
      .slice(1, -1)
      .split(",")
      .map((item) => item.trim());

    // Trim each item to remove leading/trailing whitespaces
    const trimmedItems = trimmedArray.map((item) => item.trim());

    return trimmedItems;
  }

  async function writeData() {
    setloading(true);
    await addDoc(dbRef, {
      projectID: uuidv4(),
      projectName: projectName,
      projectPath: projectPath,
      projectRoutes: stringToList(projectRoutes),
      projectDesc: projectDesc,
    }).then(() => {
      setloading(false);
      window.location.reload(false);
    });
  }

  return (
    <div className="master">
      <header className="header shadow">
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              Navbar
            </a>

            <div>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    aria-current="page"
                    href="/"
                    onClick={() => {
                      if (
                        document.getElementById("sidenav").style.display ===
                        "none"
                      ) {
                        document.getElementById("sidenav").style.display =
                          "block";
                      } else {
                        document.getElementById("sidenav").style.display =
                          "none";
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-justify"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div className="row">
        <div className="col-2" style={{ padding: "0" }} id="sidenav">
          {/* Side Nav */}
          <SidNav active={"projects"}></SidNav>
        </div>
        <div className="col" style={{ padding: "0", height: "100vh" }}>
          <main id="main">
            {/* Section Title */}
            <div id="breadcrum">
              <h5>Projects</h5>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Dashboard</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Projects
                  </li>
                </ol>
              </nav>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <div className="row">
                        <div className="col-8">
                          <h5>Add New Project</h5>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <p>
                            <i>
                              You can register your new project here. Please
                              make sure you register the route as well.
                            </i>
                          </p>
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Project Name:
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Project Name ..."
                              onChange={(e) => {
                                setprojectName(e.target.value);
                              }}
                            />
                          </div>
                          <div class="mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Description:
                            </label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              onChange={(e) => {
                                setprojectDesc(e.target.value);
                              }}
                            ></textarea>
                          </div>
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Project Parent Path:
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput1"
                              placeholder="/<project name>"
                              onChange={(e) => {
                                setprojectPath(e.target.value);
                              }}
                            />
                          </div>
                          <div class="mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              List of routes:
                            </label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="[route1, route2, route3]"
                              onChange={(e) => {
                                setprojectRoutes(e.target.value);
                              }}
                            ></textarea>
                          </div>

                          {loading ? (
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              disabled
                            >
                              Loading ...
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={writeData}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default View;
