// src/components/About.js
import React, { useState, useEffect } from "react";
import "./../styles/styles.css";

import readData from "./../../../functions/DB";

import SidNav from "./sidenav";

function About() {
  const [projects, setprojects] = useState([]);
  const [apis, setapis] = useState([]);
  const [loading, setloading] = useState(false);
  const [routesCount, setroutesCount] = useState(0);

  useEffect(() => {
    setroutesCount(0);
    const getData = async () => {
      setloading(true);
      await readData("Projects").then((e) => {
        setprojects(e);
        setloading(false);
        e.map((obj) => {
          console.log(obj);
          setroutesCount(
            (prevRoutesCount) => prevRoutesCount + obj.projectRoutes.length
          );
          return null;
        });
      });

      await readData("APIs").then((e) => {
        setapis(e);
        setloading(false);
        e.map((obj) => {
          setroutesCount(
            (prevRoutesCount) => prevRoutesCount + obj.APIRoutes.length
          );
          return null;
        });
      });
    };

    getData();
  }, []);
  return (
    <div className="master">
      <header className="header shadow">
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              Navbar
            </a>

            <div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/"
                    onClick={() => {
                      if (
                        document.getElementById("sidenav").style.display ===
                        "none"
                      ) {
                        document.getElementById("sidenav").style.display =
                          "block";
                      } else {
                        document.getElementById("sidenav").style.display =
                          "none";
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-justify"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div className="row">
        <div className="col-2" style={{ padding: "0" }} id="sidenav">
          {/* Side Nav */}
          <SidNav active={"dashboard"}></SidNav>
        </div>
        <div className="col" style={{ padding: "0", height: "100vh" }}>
          <main id="main">
            {/* Section Title */}
            <div id="breadcrum">
              <h5>Dashboard master</h5>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Library
                  </li>
                </ol>
              </nav>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row metrics-card">
                    <div className="col-sm-4 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-8">
                              <h5>Projects</h5>
                            </div>
                            <div className="col right">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-3">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  className="bi bi-archive"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5zm13-3H1v2h14zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                                </svg>
                              </div>
                            </div>
                            <div className="col center mt-3">
                              <h6>{projects.length}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-8">
                              <h5>APIs</h5>
                            </div>
                            <div className="col right">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-3">
                              <div className="card-icon-2 rounded-circle d-flex align-items-center justify-content-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27"
                                  height="27"
                                  fill="currentColor"
                                  className="bi bi-link-45deg"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                  <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
                                </svg>
                              </div>
                            </div>
                            <div className="col center mt-3">
                              <h6>{apis.length}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-8">
                              <h5>Routes</h5>
                            </div>
                            <div className="col right">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-3">
                              <div className="card-icon-3 rounded-circle d-flex align-items-center justify-content-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  className="bi bi-router"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.525 3.025a3.5 3.5 0 0 1 4.95 0 .5.5 0 1 0 .707-.707 4.5 4.5 0 0 0-6.364 0 .5.5 0 0 0 .707.707Z" />
                                  <path d="M6.94 4.44a1.5 1.5 0 0 1 2.12 0 .5.5 0 0 0 .708-.708 2.5 2.5 0 0 0-3.536 0 .5.5 0 0 0 .707.707ZM2.5 11a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m4.5-.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0m2.5.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m1.5-.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0m2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0" />
                                  <path d="M2.974 2.342a.5.5 0 1 0-.948.316L3.806 8H1.5A1.5 1.5 0 0 0 0 9.5v2A1.5 1.5 0 0 0 1.5 13H2a.5.5 0 0 0 .5.5h2A.5.5 0 0 0 5 13h6a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5h.5a1.5 1.5 0 0 0 1.5-1.5v-2A1.5 1.5 0 0 0 14.5 8h-2.306l1.78-5.342a.5.5 0 1 0-.948-.316L11.14 8H4.86L2.974 2.342ZM14.5 9a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5z" />
                                  <path d="M8.5 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                                </svg>
                              </div>
                            </div>
                            <div className="col center mt-3">
                              <h6>{routesCount}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100%" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : null}

                  <div className="row content-card row-cols-1 row-cols-md-3 g-4">
                    {projects.map((project, index) => {
                      const projectRoutes = project.projectRoutes;
                      return (
                        <div className="col" key={index}>
                          <div className="card ">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-8">
                                  <h5>{project.projectName}</h5>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col">
                                  <p>
                                    <i>{project.projectDesc}</i>
                                  </p>
                                  <p>Links</p>
                                  <ul className="list-group">
                                    {projectRoutes.map((route, index) => {
                                      const path =
                                        project.projectPath + "/" + route;
                                      return (
                                        <li
                                          className="list-group-item"
                                          key={index}
                                        >
                                          <a href={path}>{path}</a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-3"></div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default About;
