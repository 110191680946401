import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Index from "./projects/master/components/index";
import Project from "./projects/master/components/projects";
import APIs from "./projects/master/components/apis";

// Projects imports
import Records from "./projects/records/components/index";

function App() {
  return (
    <BrowserRouter>
      {/* Define routes */}
      <Routes>
        {/* Master Routes */}
        <Route path="*" exact element={<Index></Index>} />
        <Route path="/" exact element={<Index></Index>} />
        <Route path="/projects" exact element={<Project></Project>} />
        <Route path="/apis" exact element={<APIs></APIs>} />

        {/* Project based Routes */}

        <Route path="/records" exact element={<Records></Records>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
